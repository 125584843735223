import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import projectsStyles from "./projects.module.scss"
import Head from "../components/head"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ProjectsPage = props => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPortfolioProject(
        sort: { fields: contentfulid, order: ASC }
      ) {
        edges {
          node {
            title
            description {
              json
            }
            screenshot {
              file {
                url
              }
            }
            contentfulid
            slug
            stack
            demoLink
            repoLink
            video {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  return (
    <Layout>
      <Head title="Projects" />
      <h1 className="page-title">Portfolio</h1>
      <div className={projectsStyles.projectsContainer}>
        {data.allContentfulPortfolioProject.edges.map(edge => {
          return (
            <div className={projectsStyles.project}>
              <div className={projectsStyles.imageWrapper}>
                <video
                  width="100%"
                  autoPlay
                  muted
                  loop
                  playsinline
                  poster={edge.node.screenshot.file.url}
                >
                  <source
                    src={edge.node.video.file.url}
                    type="video/mp4"
                  ></source>
                </video>
              </div>
              <div className={projectsStyles.contentWrapper}>
                <span className={projectsStyles.stack}>{edge.node.stack}</span>
                <h4>{edge.node.title}</h4>
                {documentToReactComponents(edge.node.description.json, options)}
                <span>
                  <a href={edge.node.demoLink}>Demo</a>
                </span>{" "}
                |{" "}
                <span>
                  <a href={edge.node.repoLink}>Repo</a>
                </span>
              </div>
              <video width="320" controls>
                <source
                  src={edge.node.video.file.url}
                  type="video/mp4"
                ></source>
              </video>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default ProjectsPage
